<template>
    
    <div>
        <!-- <v-card outlined>
            <v-card-text class="pa-10" style="background-color:#f5f5f5;">
                <p style="font-size:25px;"><v-btn color="#597195" small dark><v-icon color="#FFFFFF" size="18" class="mr-1">mdi-plus-circle</v-icon>메뉴추가</v-btn> 를 선택해 전화한 고객에게 안내할 메뉴를 만들어요.</p>
                <ul style="font-size:18px; line-height:45px; color:#777777;">
                    <li>좌측 메뉴명을 선택하여 메뉴를 수정할 수 있습니다.</li>
                    <li>좌측메뉴를 클릭 후 <v-btn color="#597195" small dark><v-icon color="#FFFFFF" size="18" class="mr-1">mdi-plus-circle</v-icon>메뉴추가</v-btn> 를 누르면 하위메뉴를 만들 수 있습니다. (하위메뉴는 3개까지 가능)</li>
                    <li>좌측메뉴를 선택하지 않고 <v-btn color="#597195" small dark><v-icon color="#FFFFFF" size="18" class="mr-1">mdi-plus-circle</v-icon>메뉴추가</v-btn> 를 누르면 최상단 메뉴를 만들 수 있습니다.</li>
                </ul>
            </v-card-text>
        </v-card> -->
        <!-- <v-card>
            <v-card-text class="ma-0 pa-0"> -->
                <v-img
                    min-width="400px"
                    max-width="900px"
                    src="/img/scn_info.png"          
                    class="ma-0 pa-0"
                ></v-img>
            <!-- </v-card-text>
        </v-card> -->
    </div>
</template>
<script>

export default {
    name: 'svc_voice_scn_detail_desc',
    components: {
    },
    data: () => ({
    }),
    created () { 
    },
    computed: {
    },  
    methods: { 
    }
}</script>